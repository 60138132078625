import { OpenLinkIcon } from 'components/icons/openlink'
import { EmailSent } from 'components/icons/emailSent'
import { RasaContext } from 'context'
import React, {Component} from 'react'
import { DEFAULT_DATE_FORMAT } from '../../constants'
import * as GenericRedux from '../../generic/genericRedux'
import { copyToClipboard, getEnvironmentSuffix } from '../../generic/utility'
import './styles.css'
import { Dataset } from 'generic/dataset'
import { format } from 'date-fns'

interface ViewInBrowserLinkComponentProps {
  name: string,
  description: string,
  link: string,
}

const ViewInBrowserLinkComponent = (props: ViewInBrowserLinkComponentProps) =>
  <div className="view-in-browser-link">
    <div className="left-part">
      <div className="first-line">
        <div className="name">{props.name}</div>
      </div>
      <div className="description">{props.description}</div>
    </div>
    <div className="middle-part">
      <a target="_blank" href={props.link} className="open-link"><OpenLinkIcon /></a>
    </div>
    <div className="right-part">
      <input type="text" value={props.link} readOnly={true}/>
      <button onClick={() => copyToClipboard(props.link) }>Copy</button>
    </div>
  </div>

interface WidgetsViewInBrowserState {
  communityId: string
  communityGuid: string
  issues: any[],
  lastIssue: any,
}

class WidgetsViewInBrowserComponent extends Component<any, WidgetsViewInBrowserState> {
  public static contextType = RasaContext;

  constructor(props: any) {
    super(props)

    this.state = {
      communityId: '',
      communityGuid: '',
      issues: [],
      lastIssue: {},
    }
  }

  public componentDidMount = () => {
    this.context.user.init().then(({person, activeCommunity}) => {
      this.setState({
        communityId: activeCommunity.communityId,
        communityGuid: activeCommunity.data.community_guid,
      }, () => {
        this.loadLastIssue()
      })
    })
  }

  private loadLastIssue = () => {
    return new Dataset().loadCommunityDataset('communityIssues', this.state.communityId, null)
      .then((communityIssues) => {
        if (communityIssues[0]) {
          this.setState({
            issues: communityIssues[0],
            lastIssue: {
              ...communityIssues[0][0],
            },
          })
        }
        return {}
      })
  }

  public render() {
    if (!this.state.issues.length) {
      return null
    }
    return (
      <div className="widgets-tools-view-in-browser">
        <div className="contentpane-header-wrapper">
          <div className="contentpane-icon-wrapper">
            <EmailSent/>
          </div>
          <div className="header">
            <div className="contentpane-section-title-wrapper">
              <div className="section-header-text">View In Browser</div>
            </div>
          </div>
        </div>
        <ViewInBrowserLinkComponent
          name="View In Browser"
          description="This URL will display the newsletter from the date provided at the end of this URL"
          link={this.viewInBrowserUrl()}/>

     </div>
    )
  }

  private getLastIssueDate = () => {
    return this.state.lastIssue.send_at ? format(this.state.lastIssue.send_at, DEFAULT_DATE_FORMAT) : format(new Date(), DEFAULT_DATE_FORMAT)
  }

  private viewInBrowserUrl = () => {
    return `https://pages${getEnvironmentSuffix()}.rasa.io/newsbrief/${this.state.communityGuid}?date=${this.getLastIssueDate()}`
  }
}

export const WidgetsViewInBrowser = GenericRedux.registerNewDatasetComponent(
  WidgetsViewInBrowserComponent, 'widgets_view_in_browser')
